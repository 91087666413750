const axios = require("axios");
const csv = require("csvtojson");

const load = async (id, gid) => {
  var url = `https://docs.google.com/spreadsheets/d/${id}/export?format=csv`;

  if (gid) {
    url = `${url}&gid=${gid}`;
  }

  const data = await axios.get(url).then(res => res.data);
  const json = await csv()
    .fromString(data)
    .then(json => json);

  return json;
};

export default load;
