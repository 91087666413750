import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import AttendanceStore from "./AttendanceDashboard/store.js";
import ConfigurationStore from "./Configuration/store.js";

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: "studentqr-attendance"
});

export default new Vuex.Store({
  modules: {
    attendance: AttendanceStore,
    holiday: ConfigurationStore
  },
  state: {},
  mutations: {},
  actions: {},
  plugins: [vuexPersist.plugin]
});
