import schm from "schm";
const WEB_ADMIN = 1;
const TEACHER = 2;

const AttSchema = schm({
  email: {
    required: true,
    type: String
  },
  actionYear: {
    required: true,
    type: Number
  },
  actionMonth: {
    required: true,
    type: Number
  },
  kelasID: {
    required: true,
    type: String
  },
  kelasName: {
    required: true,
    type: String
  },
  teacherName: {
    required: false,
    type: String
  },
  userType: {
    required: true,
    type: Number,
    enum: [WEB_ADMIN, TEACHER]
  }
});

export default data => {
  return AttSchema.validate(data);
};
