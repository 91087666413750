import axios from "axios";
import * as R from "ramda";
import moment from "moment";

const api = path => {
  //return `https://temp.studentqr.com/api/${path}`;
  return `https://api.studentqr.com/api/${path}`;
};

const ATTEND_ENUM = {
  absent: 0,
  attend: 1
};

const STATUS_ENUM = {
  onTime: 1,
  late: 2
};

const getPassedDays = month => {
  const diff = moment()
    .add(1, "day")
    .diff(month, "days");
  const dayOfmonths = month.daysInMonth();
  return dayOfmonths > diff ? diff : dayOfmonths;
};

export default {
  state: {
    students: [],
    className: "Loading...",
    reportMonth: "...",
    teacherName: "...",
    totalDays: 0,
    loaded: false,
    logged: true,
    kelasID: "",
    email: "",
    userType: 0
  },
  mutations: {
    SET_STUDENT_DATA(state, data) {
      if (data.length == 0) {
        state.students = data;
        state.loaded = true;
        state.logged = false;
      } else {
        state.students = data;
        state.loaded = true;
        state.logged = true;
      }
    },
    UPDATE_STUDENT_DATA(state, { student_idx, day_idx, newAttendance }) {
      Object.assign(state.students[student_idx].attendance[day_idx], {
        ...newAttendance,
        late: newAttendance.late == STATUS_ENUM.late
      });
    },
    UPDATE_META(
      state,
      {
        className,
        reportMonth,
        teacherName,
        totalDays,
        kelasID,
        email,
        userType
      }
    ) {
      Object.assign(state, {
        className,
        reportMonth,
        teacherName,
        totalDays,
        kelasID,
        email,
        userType
      });
    },
    SET_JWT(state, jwt) {
      // axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
      state.logged = !!jwt;
    }
  },
  actions: {
    "jwt.load"(context, jwt) {
      //do validation on jwt...
      context.commit("SET_JWT", jwt);
    },
    async "attendance.load"(
      context,
      {
        email,
        actionYear,
        actionMonth,
        kelasID,
        kelasName,
        teacherName,
        userType,
        password
      }
    ) {
      const { success, students } = await axios
        .post(api("kelas/attendance"), {
          email,
          actionYear,
          actionMonth,
          kelasID,
          password,
          userType
        })
        .then(res => {
          console.log(res.data)
          if (!res.data.success) {
            return res.data.msg;
          } else {
            return res.data;
          }
        })
        .catch(error => {
          // context.commit("SET_JWT", null);
          context.commit("SET_STUDENT_DATA", []);
          return { success: false, students: [], error };
        });

      if (!success) {
        return;
      }

      const month = moment(`${actionMonth}`, "M")
      const totalDays = month.daysInMonth();
      const passedDays = getPassedDays(month)
      
      const cleanUpName = str => str.replace(/[\\]/g, "");
      context.commit(
        "SET_STUDENT_DATA",
        R.pipe(
          R.map(student => ({
            name: cleanUpName(student.firstName),
            studentID: student._id,
            attendance: R.pipe(
              R.map(data => ({
                attend: true,
                late: data.status == STATUS_ENUM.late,
                noRecord: false,
                checkInDay: moment(data.actionDate,'YYYYMMDD').date()
              })),
              R.indexBy(R.prop("checkInDay")),
              mapping => {
                return R.keys([...Array(totalDays)]).map(index => {
                  const checkInDay = parseInt(index) + 1
                  const noRecord = checkInDay > passedDays
                  return (
                    mapping[parseInt(index) + 1] || {
                      attend: false,
                      late: false,
                      noRecord,
                      checkInDay
                    }
                  );
                });
              }
            )(student.attendance)
          }))
          // R.indexBy(R.prop("studentID"))
        )(students)
      );

      context.commit("UPDATE_META", {
        className: kelasName,
        reportMonth: moment(`${actionYear}-${actionMonth}`, "YYYY-M").format(
          "YYYY-MM"
        ),
        teacherName: teacherName,
        totalDays,
        kelasID,
        email,
        userType
      });

      return (R.head(students) || {}).schoolID;
    },
    "attendance.update"(context, { student_idx, day_idx, data, password }) {
      const newAttendance = {
        attend: data.attend ? ATTEND_ENUM.attend : ATTEND_ENUM.absent,
        late: data.late ? STATUS_ENUM.late : STATUS_ENUM.onTime,
        noRecord: false
      };

      context.commit("UPDATE_STUDENT_DATA", {
        student_idx,
        day_idx,
        newAttendance
      });

      return axios
        .post(api("kelas/attendance/update"), {
          email: context.state.email,
          password,
          checkInDate: data.date.format("YYYYMMDD"), //YYYYMMDD,
          kelasID: context.state.kelasID,
          studentID: data.studentID,
          attend: newAttendance.attend,
          status: newAttendance.late,
          userType: context.state.userType
        })
        .then(() => {
          context.commit("UPDATE_STUDENT_DATA", {
            student_idx,
            day_idx,
            newAttendance
          });
        });
    }
  }
};
