<template>
  <div
    @click.stop="preventClick"
    class="popover is-popover-top is-popover-active noselect animated fadeIn faster"
  >
    <div class="popover-content " style="width:250px;" :style="getTransaform">
      <div class="level is-mobile is-marginless">
        <div class="level-start">
          <div class="title is-6">
            Update Attendance
          </div>
        </div>
        <div class="level-end">
          <a @click="$emit('close')" class="button is-small is-white"
            ><span class="is-hidden-mobile">(esc) </span> ⓧ</a
          >
        </div>
      </div>
      <div class="column">
        <span class="heading">{{ att.date.format("DD MMM YYYY (dddd)") }}</span>
        <h3 class="title is-6">{{ student.name }}</h3>
        <br />
        <div v-if="!canEdit">
          <span v-if="att.noRecord" class="tag">No Record</span>
          <span v-else-if="att.attend" class="tag is-success">Attend</span>
        </div>
        <div class="field is-grouped" v-else>
          <div class="control is-expanded">
            <div class="field">
              <input
                ref="radio"
                :id="`att-${student.name}-${att.date}`"
                type="checkbox"
                class="switch is-small is-rounded is-success"
                @changex="$emit('attendanceChanged', id)"
                v-model="attend"
              />
              <label :for="`att-${student.name}-${att.date}`">Attend</label>
            </div>
          </div>
          <div v-if="attend" class="control">
            <div class="field">
              <input
                ref="radio2"
                :id="`late-${student.name}-${att.date}`"
                type="checkbox"
                class="switch is-small is-rounded is-warning"
                @changex="$emit('attendanceChanged', id)"
                v-model="late"
              />
              <label :for="`late-${student.name}-${att.date}`">Late</label>
            </div>
          </div>
        </div>
        <div class="field">
          <div class="control">
            <button @click="update" class="button is-primary is-fullwidth">
              UPDATE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["show", "student", "att", "id"],
  data() {
    return {
      offsetX: 0,
      pointerX: 0,
      attend: false,
      late: false
    };
  },
  methods: {
    preventClick() {},
    close() {
      this.$emit("close");
    },
    update() {
      this.$emit("attendanceChanged", {
        attend: this.attend,
        late: this.attend ? this.late : false,
        studentID: this.student.studentID,
        date: this.att.date
      });
      this.close();
    }
  },
  mounted() {
    this.attend = this.att.attend || false;
    this.late = this.att.late || false;
    this.$refs.radio.focus();
    const left = this.$el.children[0].getClientRects()[0].left;
    const right = this.$el.children[0].getClientRects()[0].right;
    const screenW = document.body.clientWidth;
    this.pointerX = -10;

    if (left < 0) {
      this.offsetX = -left + 15;
      this.pointerX = -this.offsetX - 15;
    }

    if (right > screenW) {
      this.offsetX = screenW - right - 15;
      this.pointerX = -this.offsetX - 15;
    }
  },
  computed: {
    getTransaform() {
      return `margin-left: ${this.offsetX}px; --offsetX: ${this.pointerX}px`;
    },
    canEdit() {
      return true;
    }
  }
};
</script>

<style lang="scss">
.popover .popover-content::before {
  margin-left: var(--offsetX) !important;
  margin-top: -8px !important;
}
</style>
