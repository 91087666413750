<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import "./styles.scss";

export default {};
</script>
