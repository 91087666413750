<!-- eslint-disable -->
<template>
  <div>
    <div v-if="!loggedIn" class="hero is-fullheight">
      <div class="hero-body" style="width:100%">
        <div>
          <h1 class="title is-1 is-marginless">401</h1>
          <span>Unauthorized</span>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="card is-hidden-print is-hidden-mobile">
        <div class="column">
          <div class="level">
            <div class="level-start"></div>
            <div class="level-end">
              <a target="_BLANK" :href="configureLink" class="button"
                >Configure holiday</a
              >
            </div>
          </div>
        </div>
      </div>
      <class-attendance v-if="loaded" :password="password" :offdays="offdays" />
      <div v-else class="hero is-fullheight">
        <div class="hero-body" style="width:100%">
          <span class="button is-fullwidth is-white is-loading"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import AttendanceRequest from "./AttendanceRequest";
import ClassAttendance from "./ClassAttendance";

/*
http://localhost:3000/#/?year=2019&month=7&email=sriaag.qr%40gmail.com&kelasID=5d0b3449c6cef17f53d4663a&kelasName=2%20Biruni&teacherName=Salleh&userType=0&password=[temp_SQR2019]
https://attendance.studentqr.com/#/?year=2019&month=7&email=sriaag.qr%40gmail.com&kelasID=5d0b3449c6cef17f53d4663a&kelasName=2%20Biruni&teacherName=Salleh
*/

export default {
  components: {
    ClassAttendance
  },
  data() {
    return {
      schoolId: false
    };
  },
  computed: {
    configureLink() {
      return `/#/config?id=${this.schoolId}`;
    },
    loggedIn() {
      return this.$store.state.attendance.logged;
    },
    loaded() {
      return this.$store.state.attendance.loaded;
    },
    jwt() {
      return this.$route.query.jwt;
    },
    actionYear() {
      return this.$route.query.year;
    },
    actionMonth() {
      return this.$route.query.month;
    },
    password() {
      return this.$route.query.password;
    },
    email() {
      return this.$route.query.email;
    },
    kelasID() {
      return this.$route.query.kelasID;
    },
    kelasName() {
      return this.$route.query.kelasName;
    },
    userType() {
      return this.$route.query.userType;
    },
    teacherName() {
      return this.$route.query.teacherName;
    },
    queryString() {
      return {
        email: this.email,
        actionYear: this.actionYear,
        actionMonth: this.actionMonth,
        kelasID: this.kelasID,
        kelasName: this.kelasName,
        teacherName: this.teacherName,
        userType: this.userType,
        password: this.password
      };
    },
    offdays() {
      return this.$store.getters.offdates(moment().format("YYYY"));
    }
  },
  mounted() {
    this.loadData();
  },
  watch: {
    queryString() {
      this.loadData();
    }
  },
  methods: {
    loadData() {
      AttendanceRequest(this.queryString)
        .then(() => {
          this.$store
            .dispatch("attendance.load", this.queryString)
            .then(schoolId => {
              this.schoolId = schoolId;
              this.$store.dispatch("holiday.fetch", schoolId);
            });
        })
        .catch(() => {
          this.$store.dispatch("jwt.load");
        });
    }
  }
};
</script>
