import schm from "schm";
import moment from "moment";
import computed from "schm-computed";

const validDate = value => moment(value).isValid();

const toId = data => data.name.toLowerCase().replace(/[^a-z0-9]+/g, "_");

const HolidaySchema = schm(
  {
    entity: {
      type: String,
      default: "holidays"
    },
    name: {
      type: String,
      required: true
    },
    start: {
      type: String,
      required: true,
      set: date => moment(date).format("YYYY-MM-DD"),
      validate: [validDate, "Start date is not valid"]
    },
    end: {
      type: String,
      required: true,
      set: date => moment(date).format("YYYY-MM-DD"),
      validate: [validDate, "Start date is not valid"]
    },
    schoolId: {
      type: String,
      required: true
    }
  },
  computed({
    holidayId: toId,
    PK: data => data.schoolId,
    SK: data => `holiday.${toId(data)}`,
    GS1: () => "holidays"
  })
);

export default HolidaySchema;
