import * as R from "ramda";
import moment from "moment";
import range from "lodash/range";

const studentSummary = (total_days, offdays) =>
  R.pipe(
    student => student.attendance,
    R.filter(d => d.attend),
    list => {
      const attend = list.length;
      return {
        attend,
        total_days,
        offdays,
        school_days: total_days - offdays,
        percentage: attend / (total_days - offdays)
      };
    }
  );

const processStudent = (total_days, offdays) => student => {
  return {
    ...student,
    summary: studentSummary(total_days, offdays)(student)
  };
};

const getTotalDays = month => {
  const diff = moment()
    .add(1, "day")
    .diff(month, "days");
  const dayOfmonths = month.daysInMonth();
  return dayOfmonths > diff ? diff : dayOfmonths;
};

const getOverall = (total_days, offdays, students) => {
  const offdayMap = R.pipe(R.indexBy(data => moment(data[0]).format("D")))(
    offdays
  );

  const totalStudents = students.length;

  const attendance = R.pipe(
    R.pluck("attendance"),
    R.flatten,
    R.groupBy(R.prop("checkInDay")),
    R.map(
      R.pipe(
        R.filter(d => d.attend),
        d => d.length
      )
    )
  )(students);

  return R.pipe(
    d => range(1, d + 1),
    R.map(day => ({
      day,
      isOffday: offdayMap[day] ? true : false,
      totalStudents: offdayMap[day] ? 0 : totalStudents,
      attendance: attendance[day],
      absent: offdayMap[day] ? 0 : totalStudents - attendance[day]
    })),
    R.indexBy(R.prop("day"))
  )(total_days);
};

export default (students, month, offdays) => {
  const total_days = getTotalDays(month);

  const monthOffdays = R.pipe(
    R.toPairs,
    R.filter(d => {
      const date = moment(d[0]);
      return (
        date.isBefore(moment().add(1, "day")) && date.isSame(month, "month")
      );
    })
  )(offdays);

  const total_offdays = monthOffdays.length;

  const studentsSummary = R.map(
    processStudent(total_days, total_offdays),
    students
  );

  const summary = getOverall(total_days, monthOffdays, students);

  return {
    students: studentsSummary,
    summary
  };
};
