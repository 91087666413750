<template>
  <div class="table-container">
    <table
      class="table is-fullwidth is-bordered is-size-7 is-narrow is-hoverable"
    >
      <tfoot class="print-only">
        <tr>
          <td colspan="32">
            <div class="level">
              <div class="level-start">
                <div class="field is-grouped">
                  <div class="control">
                    <span class="tags has-addons">
                      <span
                        class="tag is-white"
                        style="padding-right:2px; padding-left:2px;"
                      >
                        Indicator:
                      </span>
                      <span
                        class="tag is-white"
                        style="padding-right:2px; padding-left:2px;"
                      ></span>
                    </span>
                  </div>

                  <div class="control">
                    <div class="tags has-addons">
                      <span
                        class="tag is-white"
                        style="padding-right:2px; padding-left:2px;"
                        >✓</span
                      >
                      <span
                        class="tag is-white"
                        style="padding-right:2px; padding-left:2px;"
                        >: Attend on-time</span
                      >
                    </div>
                  </div>

                  <div class="control">
                    <div class="tags has-addons">
                      <span
                        class="tag is-white"
                        style="padding-right:2px; padding-left:2px;"
                        >⍻</span
                      >
                      <span
                        class="tag is-white"
                        style="padding-right:2px; padding-left:2px;"
                        >: Attend late</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
      <thead>
        <tr>
          <th colspan="32">BORANG KEDATANGAN PELAJAR {{ actionYear }}</th>
        </tr>
        <tr v-if="teacherName">
          <th colspan="2">Guru</th>
          <th colspan="30">{{ teacherName }}</th>
        </tr>
        <tr>
          <th colspan="2">Kelas</th>
          <th colspan="30">{{ className }}</th>
        </tr>
        <tr>
          <th colspan="2">Bulan</th>
          <th colspan="30">{{ month }}</th>
        </tr>
        <tr>
          <th rowspan="2">Bil</th>
          <th rowspan="2">Nama</th>
          <th class="has-text-centered" v-for="i in days" :key="i">
            {{ (i + 1) | pad }}
          </th>
          <th colspan="1" class="has-text-right">
            JUMLAH KEHADIRAN
          </th>
        </tr>
        <tr>
          <th class="has-text-centered" v-for="i in days" :key="i">
            {{ getDay(i + 1) }}
          </th>
          <th class="has-text-right">Bulan Semasa</th>
          <!-- <th>Bulan Lalu</th>
          <th>Jumlah</th> -->
        </tr>
      </thead>
      <tbody class="vsheet">
        <tr v-for="(student, y) in students" :key="student.name">
          <td>{{ (y + 1) | pad }}</td>
          <td style="min-width:200px;">{{ student.name }}</td>
          <td
            :ref="`c-${x}-${y}`"
            @dblclick.prevent="editMode"
            @click.prevent="handleClick(x, y)"
            v-for="(att, x) in attandance(student)"
            :key="`${y}.${x}`"
            class="has-text-centered"
            :class="getClass(x, y, att)"
          >
            <quick-edit
              @close="currentSelection = false"
              v-if="showPopover(x, y)"
              @attendanceChanged="data => changeAttendance(y, x, data)"
              :show="showPopover(x, y)"
              :student="student"
              :att="att"
              :id="x"
            />

            <div v-if="isOffday(x + 1)" class="popover is-popover-top">
              <span v-if="getOffdayLabel(x + 1) == 'Weekend'">W</span>
              <span v-else>P</span>
              <div class="popover-content">
                {{ getOffdayLabel(x + 1) }}
              </div>
            </div>
          </td>
          <td class="has-text-right">
            {{ student.summary.attend }} / {{ student.summary.school_days }}
          </td>
          <!-- <td></td>
          <td></td> -->
        </tr>
        <tr>
          <td class="has-text-weight-bold" colspan="2">
            JUMLAH MURID TIDAK HADIR
          </td>
          <td v-for="i in days" :key="i" class="has-text-centered">
            {{ (summary[i + 1] || {}).absent }}
          </td>
          <td></td>
          <!-- <td></td>
          <td></td> -->
        </tr>

        <tr>
          <td class="has-text-weight-bold" colspan="2">JUMLAH MURID HADIR</td>
          <td v-for="i in days" :key="i" class="has-text-centered">
            {{ (summary[i + 1] || {}).attendance }}
          </td>
          <td></td>
          <!-- <td></td>
          <td></td> -->
        </tr>

        <tr>
          <td class="has-text-weight-bold" colspan="2">
            JUMLAH KEHADIRAN SEPATUTNYA
          </td>
          <td v-for="i in days" :key="i" class="has-text-centered">
            {{ (summary[i + 1] || {}).totalStudents }}
          </td>
          <td></td>
          <!-- <td></td>
          <td></td> -->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import * as R from "ramda";
import QuickEdit from "./QuickEdit";
export default {
  props: [
    "students",
    "month",
    "className",
    "teacherName",
    "totalDays",
    "actionYear",
    "offdays",
    "summary"
  ],
  components: {
    QuickEdit
  },
  data() {
    return {
      currentSelection: false,
      isEdit: false
    };
  },
  computed: {
    days() {
      return [...Array(this.totalDays).keys()];
    },
    monthOffdays() {
      return R.pipe(
        R.toPairs,
        R.filter(d => d[0].indexOf(this.month) === 0),
        R.indexBy(data => moment(data[0]).format("D")),
        R.map(d => d[1])
      )(this.offdays);
    }
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyboard);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyboard);
  },

  methods: {
    getOffdayLabel(day) {
      return (
        this.$store.state.holiday.holidays[this.monthOffdays[day]] || {
          name: "Weekend"
        }
      ).name;
    },
    isOffday(day) {
      return this.monthOffdays[day];
    },
    changeAttendance(student_idx, day_idx, data) {
      this.$emit("attendanceChanged", student_idx, day_idx, data);
    },
    getDay(i) {
      return moment(`${this.month}-${i}`, "YYYY-MM-DD").format("ddd");
    },
    handleKeyboard(ev) {
      if (!this.currentSelection) {
        return;
      }
      if (ev.key == "ArrowDown") {
        ev.preventDefault();
        this.currentSelection.y = this.currentSelection.y + 1;
      }

      if (ev.key == "ArrowUp") {
        ev.preventDefault();
        this.currentSelection.y = this.currentSelection.y - 1;
      }

      if (ev.key == "ArrowLeft") {
        ev.preventDefault();
        this.currentSelection.x = this.currentSelection.x - 1;
      }
      if (ev.key == "ArrowRight") {
        ev.preventDefault();
        this.currentSelection.x = this.currentSelection.x + 1;
      }

      if (ev.key == "Escape") {
        ev.preventDefault();
        this.currentSelection = false;
      }

      if (ev.key == "Enter") {
        ev.preventDefault();
        this.editMode();
      }
    },
    showPopover(x, y) {
      return (
        this.currentSelection &&
        this.currentSelection.x == x &&
        this.currentSelection.y == y &&
        this.isEdit
      );
    },
    getClass(x, y, att) {
      const isSelected = this.currentSelection &&
        this.currentSelection.x == x &&
        this.currentSelection.y == y;

      const isLate = !att.noRecord && att.attend && att.late;
      const isAttend = !att.noRecord && att.attend && !att.late
      const isAbsence = !att.noRecord && !att.attend && !this.isOffday(att.checkInDay)
      const isFuture = att.noRecord
      const isOffday = this.isOffday(x + 1)

      return [
        isSelected && "is-cell-selected" || '',
        isLate && "has-background-warning" || '',
        isAttend && "has-background-success" || '',
        isAbsence && "has-background-danger" || '',
        isFuture && "has-background-light" || '',
        isOffday && "stripe" || ''
      ].join(" ");
    },
    handleClick(x, y) {
      // this.$set(this.selected,ref, true)
      this.isEdit = false;
      this.currentSelection = { x, y };
    },
    editMode() {
      this.isEdit = true;
    },
    attandance(student) {
      const firstWeek = moment(`${this.month}`, "YYYY-MM").format("w");
      
      const format = attend => {

        const date = moment(`${this.month}-${attend.checkInDay}`, "YYYY-MM-DD");
        return {
          date,
          week: parseInt(date.format("w") - firstWeek),
          date_string: date.format("YYYY-MM-DD"),
          day_name: date.format("ddd"),
          schoolday: ["Sun", "Sat"].indexOf(date.format("ddd")) == -1,
          attend: attend.attend,
          late: attend.late,
          noRecord: attend.noRecord,
          meta: attend.meta,
          checkInDay: attend.checkInDay
        };
      };
      return R.pipe(
        R.map(format)
        // R.indexBy(data => data.date.format('D')),
        // R.map(R.filter(data => data.schoolday))
      )(student.attendance);
    }
  },
  filters: {
    pad(v) {
      return `${v}`.padStart(2, "0");
    }
  }
};
</script>

<style lang="scss">
@import "bulma";
.vsheet td {
  cursor: default;
}

.is-cell-selected {
  outline: solid 4px $info;
}

.print-only {
  display: none;
}

.vsheet td.has-background-success::after {
  content: "✓";
  font-weight: bolder;
  font-size: 10pt;
}

.vsheet td.has-background-danger::after {
  content: "𝗑";
  color: $light; // font-weight: bolder;
  // font-size:10pt;
}

.vsheet td.has-background-warning::after {
  content: "⍻";
  font-weight: bolder;
  font-size: 10pt;
}

@media print {
  @page {
    size: landscape;
  }

  .is-cell-selected {
    outline: none !important;
  }

  .card {
    margin: none !important;
  }
  .is-size-7 {
    font-size: 5pt;
  }
  .vsheet td.has-background-success,
  .vsheet td.has-background-danger,
  .vsheet td.has-background-warning {
    background-color: #ffffff !important;
  }
  .vsheet td.has-background-success::after {
    content: "✓";
    font-weight: bolder;
    font-size: 10pt;
  }
  .vsheet td.has-background-danger::after {
    content: "𝗑";
    color: $light; // font-weight: bolder;
    // font-size:10pt;
  }
  .vsheet td.has-background-warning::after {
    content: "⍻";
    font-weight: bolder;
    font-size: 10pt;
  }
  #class-attendance .report-month {
    background: #ffffff !important;
    font-weight: 600;
    color: #000 !important;
    font-size: 1.2em;
  }
  .is-hidden-print {
    display: none;
  }
  .table-container {
    overflow: visible !important;
  }
  .print-only {
    display: table-footer-group;
  }
}
</style>
