<template>
  <div class="section">
    <div
      class="box is-paddingless"
      style=""
      v-for="(student, x) in students"
      :key="x"
      ref="studentbox"
    >
      <div class="card-content">
        <h2 class="title is-6">{{ student.name }}</h2>
      </div>
      <div class="column has-background-light" style="outline: solid 1px #ccc;">
        <div class="columns is-mobile is-multiline">
          <day-cell
            @select="handleSelect"
            @close="currentSelection = false"
            @attendanceChanged="attendanceChanged"
            :currentSelection="currentSelection"
            v-for="(att, y) in attandance(student)"
            :student="student"
            :x="x"
            :y="y"
            :key="`${y}.${x}`"
            :att="att"
            :day="getDay(y + 1)"
          />
          <!-- <div class="card column is-shadowless" style="position: relative; z-index:0; background: #ccc; outline: solid 0.5px #ccc; outline-offset: -0.5px;"></div> -->
        </div>
      </div>
      <div class="card-footer">
        <div class="card-footer-item"></div>
      </div>
    </div>
    <div
      class="navbar is-fixed-bottom is-transparent animated slideInUp faster delay-1s"
      style="background:none;"
    >
      <div class="column">
        <div class="box is-pulled-left " style="margin-bottom: 20px;">
          <div class="tags">
            <span class="tag is-success">Attend on-time</span>
            <span class="tag is-warning">Attend late</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "animate.css";
import moment from "moment";
import * as R from "ramda";
import DayCell from "./DayCell";
export default {
  props: ["students", "month"],
  components: {
    DayCell
  },
  data() {
    return {
      currentSelection: false,
      isEdit: false
    };
  },
  computed: {
    days() {
      return [...Array(31).keys()];
    }
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyboard);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyboard);
  },

  methods: {
    attendanceChanged(student_idx, day_idx, data) {
      this.$emit("attendanceChanged", student_idx, day_idx, data);
    },
    getDay(i) {
      return moment(`${this.month}-${i}`, "YYYY-MM-DD").format("ddd");
    },
    handleKeyboard(ev) {
      if (!this.currentSelection) {
        return;
      }
      if (ev.key == "ArrowDown") {
        ev.preventDefault();
        this.currentSelection.y = this.currentSelection.y + 1;
      }

      if (ev.key == "ArrowUp") {
        ev.preventDefault();
        this.currentSelection.y = this.currentSelection.y - 1;
      }

      if (ev.key == "ArrowLeft") {
        ev.preventDefault();
        this.currentSelection.x = this.currentSelection.x - 1;
      }
      if (ev.key == "ArrowRight") {
        ev.preventDefault();
        this.currentSelection.x = this.currentSelection.x + 1;
      }

      if (ev.key == "Escape") {
        ev.preventDefault();
        this.currentSelection = false;
      }

      if (ev.key == "Enter") {
        ev.preventDefault();
        this.editMode();
      }
    },
    showPopover(x, y) {
      return (
        this.currentSelection &&
        this.currentSelection.x == x &&
        this.currentSelection.y == y &&
        this.isEdit
      );
    },
    getClass(x, y, att) {
      return [
        this.currentSelection &&
        this.currentSelection.x == x &&
        this.currentSelection.y == y
          ? "is-cell-selected"
          : "",
        !att.noRecord && att.attend && att.late ? "has-background-warning" : "",
        !att.noRecord && att.attend && !att.late
          ? "has-background-success"
          : "",
        !att.noRecord && !att.attend ? "has-background-danger" : "",
        att.noRecord ? "has-background-light" : ""
      ].join(" ");
    },
    handleSelect(e) {
      this.isEdit = false;
      this.currentSelection = e;
    },
    handleClick(x, y, student, att) {
      // this.$set(this.selected,ref, true)
      this.isEdit = false;
      this.currentSelection = { x, y, student, att };
    },
    editMode() {
      this.isEdit = true;
    },
    attandance(student) {
      const firstWeek = moment(`${this.month}`, "YYYY-MM").format("w");

      const format = (attend, day) => {
        const date = moment(`${this.month}-${day + 1}`, "YYYY-MM-DD");
        return {
          date,
          week: parseInt(date.format("w") - firstWeek),
          date_string: date.format("YYYY-MM-DD"),
          day_name: date.format("ddd"),
          schoolday: ["Sun", "Sat"].indexOf(date.format("ddd")) == -1,
          attend: attend.attend,
          late: attend.late,
          noRecord: attend.noRecord,
          meta: attend.meta
        };
      };

      return R.pipe(
        list => list.map(format)
        // R.indexBy(data => data.date.format('D')),
        // R.map(R.filter(data => data.schoolday))
      )(student.attendance);
    }
  },
  filters: {
    pad(v) {
      return `${v}`.padStart(2, "0");
    }
  }
};
</script>

<style lang="scss">
@import "./../styles.scss";
.vsheet td {
  cursor: default;
}

.is-cell-selected {
  outline: solid 4px $info;
}
</style>
