<template>
  <div id="class-attendance">
    <div class="card is-paddingless table-container">
      <div class="card-content is-hidden-print">
        <div class="is-pulled-right">
          <span class="tag is-info report-month">{{ reportMonth }}</span>
        </div>
        <div class="level is-mobile">
          <div class="level-start">
            <div>
              <div class="title" style="margin-bottom: 10px;">
                {{ className }}
              </div>
              <span class="heading">{{ teacherName }}</span>
            </div>
          </div>
          <div class="level-end"></div>
        </div>
      </div>
      <student-attendance
        class="is-hidden-mobile"
        @attendanceChanged="attendanceChanged"
        :students="students"
        :month="reportMonth"
        :className="className"
        :teacherName="teacherName"
        :totalDays="totalDays"
        :actionYear="actionYear"
        :offdays="offdays"
        :summary="report.summary"
      />
      <mobile-student-attendance
        class="is-hidden-tablet"
        @attendanceChanged="attendanceChanged"
        :students="students"
        :month="reportMonth"
        :className="className"
        :teacherName="teacherName"
        :totalDays="totalDays"
        :actionYear="actionYear"
        :offdays="offdays"
      />
    </div>
  </div>
</template>

<script>
const R = require("ramda");

import MonthlyReport from "./MonthlyReport";
import StudentAttendance from "./StudentAttendance";
import MobileStudentAttendance from "./MobileStudentAttendance";
import moment from "moment";

export default {
  props: ["attendance", "password", "offdays"],
  components: {
    StudentAttendance,
    MobileStudentAttendance
  },
  computed: {
    students() {
      return this.report.students;
    },
    report() {
      return MonthlyReport(
        this.studentsRaw,
        moment(this.reportMonth, "YYYY-MM"),
        this.offdays
      );
    },
    className() {
      return this.$store.state.attendance.className;
    },
    reportMonth() {
      return this.$store.state.attendance.reportMonth;
    },
    teacherName() {
      return this.$store.state.attendance.teacherName;
    },
    totalDays() {
      return this.$store.state.attendance.totalDays;
    },
    actionYear() {
      return this.$store.state.attendance.reportMonth.substring(0, 4);
    },
    actionMonth() {
      return 7;
    },
    days() {
      return [...Array(this.totalDays).keys()];
    },
    studentsRaw() {
      return this.$store.state.attendance.students;
    }
  },
  methods: {
    attendanceChanged(student_idx, day_idx, data) {
      this.$store.dispatch("attendance.update", {
        student_idx,
        day_idx,
        data,
        password: this.password
      });
    },
    randomAttendance() {
      return Math.round(Math.random());
    },
    getPercentage(list) {
      return Math.round((R.sum(list) / this.totalDays) * 100);
    }
  },
  filters: {
    padStart(value) {
      return String(value).padStart(2, "0");
    }
  }
};
</script>

<style lang="scss">
@import "./../styles.scss";
.att-cell {
  cursor: pointer;
}

.att-cell:hover {
  cursor: pointer;
  background-color: $light;
}

.is-day {
  width: 20px;
  height: 20px;
  border: solid 1px #ccc;
}
</style>
