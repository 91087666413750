import * as api from "./api";
import moment from "moment";
import * as R from "ramda";
import range from "lodash/range";
import Holiday from "./../domain/Holiday";

export default {
  state: {
    offdays: {},
    weekends: [],
    holidays: {},
    user: false
  },
  getters: {
    weekendsDates: state => year => {
      const dayCount = moment(year, "YYYY")
        .endOf("year")
        .dayOfYear();
      return range(1, dayCount + 1)
        .map(day => {
          const date = moment().dayOfYear(day);

          return {
            date: date.format("YYYY-MM-DD"),
            day: date.format("dddd")
          };
        })
        .filter(d => state.weekends.indexOf(d.day) > -1);
    },
    holidayList(state) {
      return R.pipe(
        R.values,
        R.sortBy(d => moment(d.start, "YYYY-MM-DD"))
      )(state.holidays);
    },

    holidayDates(state, getters) {
      return R.pipe(
        // R.take(2),
        R.map(data => {
          const start = moment(data.start);
          const dur = moment(data.end).diff(start, "day") + 1;

          return R.pipe(
            R.map(off => {
              const date = start.clone().add(off, "day");
              return {
                holidayId: data.holidayId,
                name: data.name,
                date: date.format("YYYY-MM-DD")
              };
            })
          )(range(0, dur));
        }),
        R.flatten,
        R.indexBy(R.prop("date")),
        R.map(R.prop("holidayId"))
      )(getters.holidayList);
    },

    offdates: (state, getters) => year => {
      const weekends = R.pipe(
        R.indexBy(R.prop("date")),
        R.map(() => "Weekend")
      )(getters.weekendsDates(year));

      const holidays = getters.holidayDates;

      return {
        ...weekends,
        ...holidays
      };
    }
  },
  mutations: {
    holiday(state, dates) {
      state.offdays = dates;
    },
    weekends(state, days) {
      state.weekends = days;
    },
    updateHolidays(state, holidays) {
      state.holidays = holidays;
    },
    setUser(state, user) {
      state.user = user;
    }
  },
  actions: {
    "holiday.fetch"(context, schoolId) {
      return api.fetch(schoolId).then(data => {
        context.commit("updateHolidays", data.holidays);
        context.commit("weekends", data.weekends);

        if (data.weekends.length == 0) {
          context.dispatch("holiday.reset");
        }
      });
    },
    "admin.login"(context, { schoolId }) {
      if (!schoolId) {
        // ?id=5c1a735fc98da061141475a1
        return Promise.reject("invalid schoolId");
      }
      console.log("SET JWT", schoolId);
      api.setJwt(schoolId);
      context.commit("setUser", {
        schoolID: schoolId
      });
      context.dispatch("holiday.fetch");
      return schoolId;
      // return axios
      //   .post("https://temp.studentqr.com/api/auth/admin/login", {
      //     email,
      //     password
      //   })
      //   .then(res => {
      //     const { name, schoolName, username, schoolID, token } = res.data;
      //     context.commit("setUser", {
      //       name,
      //       schoolName,
      //       username,
      //       schoolID,
      //       token
      //     });

      //     api.setJwt(schoolID);
      //     context.dispatch("holiday.fetch");
      //   });
    },
    "holiday.add"(context, holiday) {
      api.addHoliday(holiday);
      const holidays = {
        ...context.state.holidays,
        [holiday.holidayId]: holiday
      };
      return context.commit("updateHolidays", holidays);
    },
    "holiday.remove"(context, holidayId) {
      api.remove(holidayId);
      const holidays = R.omit([holidayId], context.state.holidays);
      return context.commit("updateHolidays", holidays);
    },
    "holiday.reset"(context) {
      api.reset().then(preset => {
        context.dispatch("configuration.setWeekends", preset.weekends);
        R.forEach(async holiday => {
          const _holiday = await Holiday.validate({
            ...holiday,
            schoolId: context.state.user.schoolID
          });
          context.dispatch("holiday.add", _holiday);
        }, preset.holidays);
      });
    },
    "configuration.setWeekends"(context, days) {
      api.setWeekend(days);
      context.commit("weekends", days);
    },
    "configuration.setHoliday"(context, dates) {
      const newDates = R.pipe(
        R.indexBy(date => date.format("YYYY-MM-DD")),
        R.map(() => ""),
        data => ({
          ...context.state.offdays,
          ...data
        }),
        R.toPairs,
        R.sortBy(data => data[0]),
        R.indexBy(data => data[0]),
        R.map(data => data[1])
      )(dates);

      context.commit("holiday", newDates);
    }
  }
};
