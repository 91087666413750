import * as R from "ramda";
import axios from "axios";
import Holiday from "./../domain/Holiday";
import GoogleSheet from "./GoogleSheet";

let _token = false;

const addId = item => {
  if (item.entity == "weekends") {
    item.holidayId = item.name;
    return item;
  }

  return Holiday.parse(item);
};

const url = path => {
  if (process.env.NODE_ENV == "development") {
    return `https://apiv3.studentqr.com/${path}`;
  }

  return `https://apiv3.studentqr.com/${path}`;
};

export const reset = () => {
  return GoogleSheet("1zpMKUWlUdiTEz8fGDaUx05Uko4OYtgvMPtveEMzslMQ").then(
    R.pipe(R.map(addId), R.groupBy(R.prop("entity")), obj => ({
      weekends: R.pipe(R.prop("weekends"), R.pluck("name"))(obj),
      holidays: R.pipe(R.prop("holidays"))(obj)
    }))
  );
};

export const fetch = schoolId => {
  return axios
    .get(url("v1/holiday/" + (schoolId || _token)))
    .then(res => {
      return res.data;
    });
};

export const remove = holidayId => {
  return axios.delete(url(`v1/holiday/${_token}/${holidayId}`));
};

export const addHoliday = holiday => {
  return axios.post(url(`v1/holiday/${_token}`), holiday);
};

export const setWeekend = days => {
  return axios.post(url(`v1/holiday/${_token}/weekends`), {
    days,
    entity: "weekends"
  });
};

export const setJwt = token => {
  _token = token;
};
