<template>
  <div
    v-touch:tap="() => $emit('select', { att, student, x, y })"
    class="level-item is-marginless column is-2 has-text-centered is-flex is-paddingless is-vcentered is-centered"
    :style="getStyle"
    :class="attClass"
  >
    <div class="">
      <span class="heading is-marginless is-size-7">{{ day }}</span>
      <span class="has-text-weight-bold is-size-6">{{ date }}</span>
      <quick-edit
        :key="`${student.name}-${x}-${y}`"
        class=""
        style="position:relative; top:-20px;"
        v-if="isCurrentSelected"
        :student="student"
        :show="isCurrentSelected"
        :att="att"
        :id="x"
        @close="$emit('close')"
        @attendanceChanged="attendanceChanged"
      />
    </div>
  </div>
</template>

<script>
import QuickEdit from "./QuickEdit";

export default {
  props: ["att", "day", "student", "x", "y", "currentSelection"],
  components: {
    QuickEdit
  },
  data() {
    return {
      w: 50
    };
  },
  mounted() {
    this.w = this.$el.parentNode.clientWidth / 7;
  },
  computed: {
    isCurrentSelected() {
      return (
        this.currentSelection.x == this.x && this.currentSelection.y == this.y
      );
    },
    getStyle() {
      return `width:${this.w}px; height: ${this.w}px;`;
    },
    attendace() {
      return this.att;
    },
    date() {
      return this.attendace && this.attendace.date.format("DD");
    },
    attClass() {
      if (this.attendace.noRecord) {
        return "has-background-light bordered-cell";
      } else if (this.attendace.attend && !this.attendace.late) {
        return "has-background-success bordered-cell-success";
      } else if (this.attendace.attend && this.attendace.late) {
        return "has-background-warning bordered-cell-warning";
      } else if (!this.attendace.attend) {
        return "has-background-light bordered-cell";
        // return 'has-background-danger bordered-cell-danger has-text-white'
      } else {
        return "has-background-dark";
      }
    }
  },
  methods: {
    attendanceChanged(student_idx) {
      this.$emit("attendanceChanged", this.x, this.y, student_idx);
    }
  }
};
</script>

<style lang="scss">
@import "./../styles.scss";
.bordered-cell {
  outline: solid 0.5px #ccc;
  outline-offset: -0.5px;
}

.bordered-cell-success {
  outline: solid 0.5px darken($success, 10%);
  outline-offset: -0.5px;
}

.bordered-cell-danger {
  outline: solid 0.5px darken($danger, 10%);
  outline-offset: -0.5px;
}

.bordered-cell-warning {
  outline: solid 0.5px darken($warning, 25%);
  outline-offset: -0.5px;
}
</style>
